import React from "react"

export const Logo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
      <path
        fill="#00cf5d"
        d="M0.000 200.000 L 0.000 400.000 200.000 400.000 L 400.000 400.000 400.000 200.000 L 400.000 0.000 200.000 0.000 L 0.000 0.000 0.000 200.000 M309.000 83.500 L 309.000 102.000 285.000 102.000 L 261.000 102.000 261.000 129.949 L 261.000 157.899 228.250 158.199 C 187.481 158.574,180.996 160.797,181.002 174.398 C 181.006 186.201,186.720 189.938,203.727 189.260 C 246.385 187.559,276.366 207.502,280.380 240.250 L 280.962 245.000 260.449 245.000 C 241.795 245.000,239.886 244.842,239.375 243.250 C 236.252 233.532,231.474 228.809,221.789 225.867 C 190.514 216.368,164.863 244.547,179.259 272.590 C 190.734 294.945,233.349 294.839,238.642 272.442 L 239.574 268.500 260.351 268.230 L 281.129 267.959 280.486 272.730 C 272.245 333.879,167.277 344.937,140.434 287.484 C 128.050 260.979,134.070 223.092,153.095 207.803 L 156.861 204.777 151.616 199.638 C 135.439 183.792,135.252 155.618,151.216 139.653 C 162.197 128.672,176.239 124.210,202.750 123.276 L 219.000 122.704 219.000 112.352 L 219.000 102.000 160.000 102.000 L 101.000 102.000 101.000 83.500 L 101.000 65.000 205.000 65.000 L 309.000 65.000 309.000 83.500 "
      />
    </svg>
  )
}
